<template>
  <div class="container mt-5">
    <form-wizard @on-complete="onComplete" step-size="xs">
      <tab-content title="Starting">
        <div class="row">
          <div class="col-sm-4 mb-4">
            <label for="date" class="form-label">Requested loading date</label>
            <input
              v-model="date"
              type="date"
              class="form-control form-control-sm"
              id="date"
            />
          </div>
          <div class="col-sm-4 mb-4">
            <label for="origin" class="form-label">Origin</label>
            <Select2
              v-model="origin"
              :options="us_cities"
              :settings="{ settingOption: value, settingOption: value }"
              @change="myChangeEvent($event)"
              @select="mySelectEvent($event)"
            />
          </div>
          <div class="col-sm-4 mb-4">
            <label for="destination" class="form-label">Destination</label>
            <Select2
              v-model="destination"
              :options="us_cities"
              :settings="{ settingOption: value, settingOption: value }"
              @change="myChangeEvent($event)"
              @select="mySelectEvent($event)"
              style="width: 100%"
            />
          </div>
        </div>
      </tab-content>
      <tab-content title="Service Type">
        <div class="row">
          <div class="col-sm-12">
            <p>Service Type</p>
          </div>
          <div class="col-sm-3 mb-4">
            <div class="form-check">
              <input
                v-model="service_type"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="LTL, truckload, small parcel"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                LTL, truckload, small parcel
              </label>
            </div>
          </div>
          <div class="col-sm-3 mb-4">
            <div class="form-check">
              <input
                v-model="service_type"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="LTL"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                LTL
              </label>
            </div>
          </div>
          <div class="col-sm-3 mb-4">
            <div class="form-check">
              <input
                v-model="service_type"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value="truckload"
              />
              <label class="form-check-label" for="flexRadioDefault3">
                Full truckload
              </label>
            </div>
          </div>
          <div class="col-sm-3 mb-4">
            <div class="form-check">
              <input
                v-model="service_type"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault4"
                value="small parcel"
              />
              <label class="form-check-label" for="flexRadioDefault4">
                Small parcel
              </label>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="Last step">
        <div class="row">
          <div class="col-sm-4 mb-4">
            <label for="equipment_type" class="form-label">Freight Class</label>
            <select
              v-model="equipment_type"
              class="form-control"
              id="equipment_type"
            >
              <option
                v-for="(item, index) in ['example 1', 'example 2']"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="equipment_type" class="form-label"
              >Truckload Equipment Type</label
            >
            <select
              v-model="equipment_type"
              class="form-control"
              id="equipment_type"
            >
              <option
                v-for="(item, index) in listequipmentType"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="description" class="form-label">Item description</label>
            <input
              v-model="description"
              type="text"
              class="form-control"
              id="description"
            />
          </div>
          <div class="col-sm-12 mb-4">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Is this shipment going to or from a trade show?</label
              >
            </div>
          </div>
          <div class="col-sm-12 mb-4">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault2"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault2"
                >Does this shipment contain hazardous items?</label
              >
            </div>
          </div>
          <div class="col-sm-12 mb-4">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault3"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault3"
                >Does this shipment contain alcohol??</label
              >
            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="length" class="form-label">Dimensions (LxWxH)</label>
            <div class="input-group">
              <input
                type="text"
                aria-label="First name"
                class="form-control"
                id="length"
                placeholder="L"
              />
              <input
                type="text"
                aria-label="First name"
                class="form-control"
                placeholder="W"
              />
              <input
                type="text"
                aria-label="Last name"
                class="form-control"
                placeholder="H"
              />
              <span class="input-group-text">in.</span>
            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="weight" class="form-label">Weight</label>
            <div class="input-group">
              <input
                type="text"
                aria-label="Last name"
                class="form-control"
                id="weight"
                placeholder="Weight"
              />
              <span class="input-group-text">lbs.</span>
            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="quantity" class="form-label">Quantity</label>
            <div class="input-group">
              <input
                type="text"
                aria-label="Last name"
                class="form-control"
                id="quantity"
                placeholder="Quantity"
              />
              <span class="input-group-text">in.</span>
            </div>
          </div>
          <div class="col-sm-12 mt-4">
            <p><b>Contact</b></p>
          </div>
          <div class="col-sm-4 mb-4">
            <label for="name" class="form-label">Full name</label>
            <input v-model="name" type="text" class="form-control" id="name" />
          </div>
          <div class="col-sm-4 mb-4">
            <label for="email" class="form-label">E-mail</label>
            <input
              v-model="email"
              type="email"
              class="form-control"
              id="email"
            />
          </div>
          <div class="col-sm-4 mb-4">
            <label for="phone" class="form-label">Phone</label>
            <input
              v-model="phone"
              type="text"
              class="form-control"
              id="phone"
            />
          </div>
          <div class="col-sm-12 mb-4">
            <p>{{ origin }}</p>
            <p>{{ service_type }}</p>
            <p>{{ equipment_type }}</p>
          </div>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { ref } from "vue";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import { us_cities, listequipmentType } from "../api/constants";
import Select2 from "vue3-select2-component";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    FormWizard,
    TabContent,
    Select2,
  },
  setup() {
    const date = ref(new Date());
    const origin = ref();
    const destination = ref();
    const service_type = ref("LTL, truckload, small parcel");
    const equipment_type = ref();
    const description = ref();
    const name = ref();
    const email = ref();
    const phone = ref();

    const calcPriceMi = () => {
      if (!date.value || !origin.value) {
        //here
        alert("Debe completar los campos");
      } else {
        //
      }
    };
    const myChangeEvent = (val) => {
      console.log(val);
    };
    const mySelectEvent = ({ id, text }) => {
      console.log({ id, text });
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    const onComplete = () => {
      alert("Finish");
    };
    return {
      myChangeEvent,
      mySelectEvent,
      us_cities,
      listequipmentType,
      date,
      origin,
      destination,
      service_type,
      equipment_type,
      description,
      name,
      email,
      phone,
      onComplete,
    };
  },
};
</script>
<style>
.select2-container {
  width: 100% !important;
}
/*.vue-form-wizard .wizard-icon-circle {
  width: 30px !important;
  height: 30px !important;
  font-size: 14px !important;
  opacity: 0;
}
.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  margin-top: 10px !important;
}
.wizard-icon {
  margin-top: 10px !important;
}*/
</style>
